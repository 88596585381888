.learnBabel-container{
    max-width: 800px;
    padding: 20px;
    margin: 0 auto;

    h3{
        font-size: 1.5rem;
        padding: 5px 0;
    }
   
    p{
        font-size: 1.1rem;
        font-weight: 200;
        padding: 5px 0;
    }

    ol{
        margin: 0;
        padding: 0;
        font-weight: 200;
        li{
            padding: 5px 0;
        }
    }
   
}