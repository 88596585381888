.usefulLinks-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;

    .usefulLinks-button{
        text-decoration: none;
        background-color: var(--color-pri-rgba);
        color: var(--white-i);
        font-size: 1.1rem;
        font-weight: 300;
        display: block;
        padding: 5px 15px;
        border: 1px solid var(--color-pri);
        border-radius: 5px;
        cursor: pointer;

        &:hover{
            border: 1px solid var(--color-sec);
        }
    }
}


.addLink-form-container{
    margin: 0 auto 20px;
    padding: 20px;
    background-color: var(--dark-blue2);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 500px;
    border-radius: 5px;

    .addLink-form-message{
        width: 100%;
        padding: 8px;
        color: var(--white);
        border-radius: 5px;
        text-align: center;
        background-color: var(--color-sec);
    }
    .addLink-form-error{
        width: 100%;
        padding: 8px;
        color: var(--white);
        border-radius: 5px;
        text-align: center;
        background-color: var(--red-transp);
    }

    .addLink-input{
        width: 100%;
        border-radius: 3px;
        border: none;
        padding: 5px;
        color: var(--black);
        font-size: 1.1rem;
        outline: none;
    }

    .addLink-button{
        background-color: var(--color-sec2);
        color: var(--white-i);
        width: 100%;
        border-radius: 3px;
        border: none;
        padding:  8px 5px;
        font-size: 1.1rem;
        cursor: pointer;

        &:hover{
            background-color: var(--color-sec-rgba);
        }
    }
}

.editLink-container{
    margin :0 auto;
    display: flex;
    flex-direction: column;
    max-width: 800px;
    height: 100%;

   .editLink-container-p{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        padding: 3px;
        border: 1px solid var(--color-pri);
        border-radius: 3px;
        font-size: 1.3rem;

        .editLink-container-delete{
            margin: 0;
            color: var(--red);
            cursor: pointer;
            border: none;

            &:hover{
                color: var(--red-transp);
            }
        }
   }
}

.usefulLinks-btn{
    background-color: var(--color-pri-rgba);
    color: var(--white-i);
    border: 1px solid var(--white-i);
    border-radius: 3px;
    padding: 6px 20px;
    margin-bottom: 20px;
    font-size: 1.2rem;

    &:hover{
        background-color: var(--gray);
    }
}

.linksCollection-json-data{
    display: flex;
    gap: 10px;
    
    a{
        text-decoration: none;
        background-color: var(--color-pri-rgba);
        color: var(--white-i);
        border: 1px solid var(--white-i);
        border-radius: 3px;
        padding: 6px 20px;
        margin-bottom: 20px;
        font-size: 1.2rem;
    
        &:hover{
            background-color: var(--gray);
        }
    }
}