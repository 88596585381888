.englishLessons-container{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;
    padding-bottom: 30px;

    .englishLessons-li{
        text-decoration: none;
        background-color: var(--color-sec);
        color: var(--black);
        display: block;
        width: 140px;
        height: 70px;
        padding: 10px;
        border: 1px solid var(--dark-blue);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        &:hover{
            background-color: var(--color-sec2);
        }

        p{
            margin: 0;
            padding: 0;
            color: var(--white-i);
            font-size: 1.2rem;
        }
    }
}

.englishLessons-text{
    display: flex;
    justify-content: center;
}