.link-back{
    margin: 0 0 20px;
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    background-color: var(--color-pri2);
    color: var(--white);
    font-size: 1rem;
    cursor: pointer;
    text-decoration: none;
    border-radius: 3px;

    &:hover{
        background-color: var(--color-pri-rgba)
    }
}

.learnPhp-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    .search-form{
        input{
            width: 100%;
            border: none;
            border-radius: 3px;
            outline: none;
            color: var(--dark-blue);
            padding: 7px 10px;
        }

    }

    .add-tutorial-box{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;

        .actionButton{
            width: 150px;
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1rem;
            font-weight: 400;
            background-color: var(--color-pri2);
            color: var(--white);
            border: none;
            border-radius: 5px;
            cursor: pointer;

            &:hover{
                background-color: var(--color-pri-rgba)
            }
        }

        form{
            width: 300px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            border: 1px solid var(--color-pri2);
            padding: 10px;
            border-radius: 5px;

            p{
                margin: 0;
                padding: 8px 10px;
                border-radius: 3px;
                width: 100%;
                text-align: center;
                font-size: 1rem;
                font-weight: 400;
                color: var(--white);
                background-color: var(--red-transp);
            }

            .text-input{
                width: 100%;
                border: none;
                border-radius: 3px;
                outline: none;
                color: var(--dark-blue);
                padding: 7px 10px;
            }
            .ratio-input{
                width: 100%;
                display: flex;
                gap: 10px;

                label{
                    font-size: 1rem;
                    color: var(--white);
                }
            }

            button{
                border: none;
                border-radius: 3px;
                padding: 8px 10px;
                cursor: pointer;
                color: var(--white);
                background-color: var(--color-sec2);

                &:hover{
                    background-color: var(--color-sec);
                }
            }
            

        }
        
       
    }

    .youtube-list{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;

        .card{
            margin: 0 auto;
            padding: 10px;
            width: 900px;
            border: 1px solid var(--white-i);
            border-radius: 5px;
            box-shadow: var(--color-pri2) 0px 3px 8px;
            text-decoration: none;
            color: var(--white);
            background-color: var(--color-pri-rgba);
            font-size: 1rem;
            font-weight: 500;
            cursor: pointer;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
    
            &:hover{
                background-color: var(--color-pri-rgba2);
            }
    
            .left{
                width: 60px;
                height: 40px;
                border: 1px solid var(--color-pri);
                border-radius: 3px;
                display: flex;
                justify-content: center;
                align-items: center;
    
                .youtube-icon{
                    font-size: 2.5rem;
                    color: var(--color-pri);
                }
            }
    
            .right{
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 5px;
    
                h1{
                    margin: 0;
                    color: var(--white);
                    font-size: 1.1rem;
                    font-weight: 400;
                }

                .author-date{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    h3{
                        margin: 0;
                        color: var(--color-pri);
                        font-size: 1rem;
                        font-weight: 300;
                    }
                    span{
                        margin: 0;
                        color: var(--white-i);
                        font-size: 0.7rem;
                        font-weight: 300; 
                    }
                }

               
            }
        }

    }
    .phpTutorialPage-container{
        margin: 0 auto;
        max-width: 1200px;
        padding: 15px;
        width: 100%;
        background-color: var(--dark-blue2);
        border-radius: 10px;
        
    
        h1{
            margin: 0 0 15px;
            font-size: 1.5rem;
            font-weight: 500;
            padding-bottom: 10px;
            border-bottom: 1px solid var(--gray);
        }
        ul{
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            flex-direction: row;
            gap: 20px;

            .left{
                flex:1;

                iframe{
                    width: 100%;
                    height: 330px;
                    border: none;
                }  
            }
            .right{
                flex:1;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 20px;

                p{
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    gap: 10px;
                    font-size: 1.2rem;
                    color: var(--white);

                    .row-desc{
                        color: var(--white-i);

                    }
                }

                .notes{
                    border-radius: 3px;
                    padding: 3px 10px;
                    background-color: var(--color-pri2);
                }
                h5{
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    text-align: left;
                    gap: 10px;
                    font-size: 0.8rem;
                    font-weight: 300;
                    color: var(--white);
                }

                
                .buttons{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                   
                    
                    button{
                        margin: 0;
                        padding: 7px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 90px;
                        color: var(--white);
                        font-size: 1rem;
                        cursor: pointer;
                        text-decoration: none;
                        border: none;
                        border-radius: 3px;

                        &:first-child{
                            background-color: var(--color-pri2);
                        }
                        &:nth-child(2){
                            background-color: var(--color-sec);
                        }
                        &:last-child{
                            background-color: var(--red-transp);
                        }

                        &:hover{
                            background-color: var(--color-pri-rgba)
                        }
                    }
                }

               
            }
        } 

        .steps-form{
            width: 100%;
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;

            input {
                width: 300px;
                padding: 5px;
                border: none;
                outline: none;
                border-radius: 3px;
                color: var(--dark-blue);
            }
            button{
                width: 300px;
                padding: 5px;
                border: none;
                border-radius: 3px;
                background-color: var(--color-pri2);
                color: var(--white);
                cursor: pointer;

                &:hover{
                    background-color: var(--color-pri-rgba);
                }
            }
           
        }

        .steps-list{
            margin: 0;
            padding: 10px;
            border-bottom: 1px solid var(--gray);
            display: flex;

            .content{
                width: 100%;
                height: 100%;
                padding-top: 0;
                display: flex;
                flex-direction: column;
                gap: 5px;

                span{
                    margin: 0;
                    padding: 5px;

                    &:first-child{
                        background-color: var(--color-sec);
                    }
                    &:last-child{
                        background-color: var(--black);
                    }
                }

            }

            button{
                margin: 0;
                padding: 7px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 60px;
                background-color: var(--red-transp);
                color: var(--white);
                font-size: 1rem;
                cursor: pointer;
                text-decoration: none;
                border-radius: 3px;
                border: none;

                &:hover{
                    background-color: var(--red)
                }
            }


        }
    }
    

   


}

@media screen and (max-width: 1200px) {
    .learnPhp-container{
        .youtube-list{
            .card{
                width: 100%; 
            }
        }
        .phpTutorialPage-container{
            ul{
                flex-direction: column;
            }
        }
    }
}
@media screen and (max-width: 600px) {
    .learnPhp-container{
        .youtube-list{
            .card{
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                .right{
                    gap: 10px;
                }
            }
        }
    }
}