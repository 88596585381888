.dashboardHeader{
    background-color: var(--dark-blue2);
    width: 100%;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    z-index: 100;

    .left{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        width: 280px;

        .logo{
            display: flex;
            align-items: center;

            .icon{
                font-size: 2rem;
                color: var(--color-pri);
            }

            .logoLink{
                margin: 0; 
                padding-left: 1rem;
                text-decoration: none;
                color: var(--white);
                font-size: 2rem;
                font-weight: 700;
                cursor: pointer;

                &:hover{
                    color:var(--white-i);
                }
            }
        }

        button{
            background-color: transparent;
            color: var(--white);
            border: none;
            cursor: pointer;
            font-size: 1.7rem;
            display: flex;
            align-items: center;
            

            &:hover{
                color: var(--white-i);
            }
        }
    }

    .right{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 30px;

        .search{
            display: flex;
            align-items: center;
            border: 0.5px solid var(--color-pri-rgba);
            padding: 5px 10px;

            input{
                border: none;
                outline: none;
                background-color: transparent;
                color: var(--white);

                &::placeholder{
                    font-size: 12px;
                    color: var(--white);
                }
            }

            .searchIcon{
                font-size: 1rem;
                cursor: pointer;

                &:hover{
                    color: var(--white-i);
                }
            }
        }

        .userProfile{
            list-style-type: none;
            display: flex;
            align-items: center;
            gap: 15px;

            li{
                .icon{
                    font-size: 1.5rem;
                    cursor: pointer;

                    &:hover{
                        color: var(--white-i);
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 750px){
    .dashboardHeader{
        .left{
            width: 100%;

        }
        .right{
            display: none;
        }
    }
}