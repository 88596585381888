.ReactPagination-container{
    height: 100%;

    p{
        padding: 10px;
    }

    .ReactPagination-crypto-box{
        background-color: var(--color-sec);
        padding: 10px;
        border-radius: 5px;

        .reactpaginationBttns{
            margin-top: 50px;
            width: 100%;
            height: 40px;
            list-style:none;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 20px 10px;
        }
        .reactpaginationBttns a{
            padding: 5px 10px;
            margin: 8px;
            border-radius: 5px;
            border: 1px solid var(--dark-blue);
            color: var(--dark-blue);
            cursor: pointer;
        }
        
        .reactpaginationBttns a:hover{
            color: var(--white);
            background-color: var(--dark-blue);
        }
        .reactpaginationActive a {
            color: var(--white);
            background-color: var(--dark-blue);
        
        }

    }
    

}



