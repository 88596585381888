
.albums-App{
    width: 100%;
    min-height: 100vh;
    background: white;
    display: flex;

    .albums-app-container{
        margin: auto;
        width: 100%;
        max-width: 540px;

        img {
            display: block;
            cursor: pointer;
        }
        .albums-selected {
            width: 320px;
            height: 240px;
            margin: 0 auto;
            border: 3px solid blue;
        
        }

        .albums-imgContainer{
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 40px 0 0 0;

            img{
                width: 117px;
                height: 84px;
                margin: 5px 0;
                box-shadow: 1px 2px 5px 2px #c5c5c5;
            }
        }
    }
}








