

.laravel-start-container{
    p{
        margin: 0;
        border-bottom: 1px solid var(--gray);
        margin-bottom: 15px;
        padding-bottom: 15px;
    }
}

.laravel-vs-container{
    p{
        margin: 0;
        border-bottom: 1px solid var(--gray);
        margin-bottom: 15px;
        padding-bottom: 15px;
        .keyboard-command-text{
            background-color: var(--color-pri);
            display: inline-block;
            margin: 0 5px;
            padding: 3px 8px;
            color: var(--dark-blue);
            border-radius: 3px;
        }
        .search-command-text{
            background-color: var(--color-sec2);
            display: inline-block;
            margin: 0 5px;
            padding: 3px 8px;
            color: var(--white);
            border-radius: 3px;
        }
    }
}

.laravel-links-container{
    p{
        margin: 0;
        border-bottom: 1px solid var(--gray);
        margin-bottom: 15px;
        padding-bottom: 15px;

        a{
            display: block;
            color: var(--white);

            &:hover{
                color: var(--color-pri);
            }
        }
    }

}