.accordion__section{
    display: flex;
    flex-direction: column;
    max-width: 800px;

    .accordion{
        margin-top: 10px;
        width: 100%;
        background-color: var(--dark-blue2);
        color: var(--white-i);
        cursor: pointer;
        padding: 1rem;
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        transition: background-color 0.6s ease;

        &:hover {
            background-color: var(--blue-transp-x);
        }

        &.active {
            background-color: var(--color-pri);
            color: var(--white-i);
        }

        .accordion__title {
            font-weight: 600;
            font-size: 1rem;
            width: 100%;
        }

        .accordion__icon {
            margin-left:auto;
            transition: transform 0.6s ease;
            color: var(--white-i);
        }
        .rotate {
            transform: rotate(90deg);
            
        }

      
    }
    
   
    
    .accordion__content {
        background-color: var(--white);
        overflow: hidden;
        transition: max-height 0.6s ease;
        /* max-height: 0; */

        .accordion__text{
            color: var(--black);
            font-weight: 400;
            font-size: 1rem;
            padding: 1rem;
        }
    }
}

