.talker-button {
    margin: 50px;
    padding: 10px 40px;
    background-color: var(--red);
    color: var(--white);
    border:2px dotted var(--dark-blue);
    border-radius: 30px;
    cursor: pointer;
}
.talker-button:hover {
    background-color: var(--light-blue);
}