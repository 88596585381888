.homeHero-container {
    position: relative;
    padding: 0 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .homeHero-top-box{
        max-width: 520px;
        text-align: left;

        .home-hero-h2{
            font-size: 3rem;
            font-weight: 400;
            padding-bottom: 20px;
            display: flex;
            align-items: center;
            gap: 20px;

            .home-hero-h2-icon{
                color: var(--color-pri-rgba);
            }
        }

        .home-hero-h3{
            font-size: 1.2rem;
            text-align: left;
            font-weight: 400;
        }
        
    }

    .homeHero-logo{
        position: inherit;
        height: 380px;
        width: 330px;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;

        .homeHero-logo-icon{
            color: var(--color-pri-rgba);
            height: 150px;
            width: 150px;
        }

        .circle-particle-container {
            position: absolute;
            height: 300px;
            width: 300px;
            border-radius: 50%;
            transform: translate(-200px);
            opacity: 0;
            animation: fade-in 1s ease 1 0.5s forwards;
        }
    }
}

@keyframes fade-in {
    to {
      transform: translate(0);
      opacity: 1;
    }
}

@media screen and (max-width: 450px){
    .homeHero-top-box h2, .homeHero-top-box h3{
        text-align: center;
    }
}