.reactExamples-accordion-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .reactExamples-accordion{
        .reactExamples-accordion-wrap{
            background:var(--color-pri-rgba);
            padding: 10px;
            border-radius: 5px;
            color:var(--white);
            display: flex;
            justify-content: space-between;
            align-items:center;
            width:100%;
            text-align:center;
            cursor:pointer;

            &:hover{
                background-color: var(--color-pri2);
                border-radius: 5px;
            }
            
            h3 {
                font-size: 1.1rem;
                font-weight: 400;
            }
    
            span {
                margin-right: 1.5rem;
                font-size: 1.5rem;
            }
        }

        .reactExamples-accordion-dropdown{
            margin-top: 10px;
            padding: 20px 0;
            background: var(--color-pri2);
            color: var(--white);
            width:100%;
            height: 100%;
            display:flex;
            flex-direction:column;
            justify-content: center;
            align-items:center;
            border: 1px solid var(--color-pri);
            border-radius: 5px;

            .reactExamples-accordion-dropdown-p{
                background-color: var(--color-sec);
                padding: 10px;
            }
        }
    } 
}