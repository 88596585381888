.dashboard-sidebar-navigation{
    position: fixed;
    width: 225px;
    min-height: 100vh;
    top: 67px;
    background-color: var(--dark-blue2);
    border-top: 1px solid var(--color-pri-rgba);
    z-index: 50;

    .sidebar-ul{
        list-style: none;
        padding: 0;
        margin: 0;

        .title{
            font-size: 0.8rem;
            font-weight: bold;
            color: var(--gray);
            margin-top: 15px;
            margin-bottom: 5px;
            padding: 0 10px;
        }

        .sidebar-li{
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 100%;
            padding: 5px;

            &.sidebarUserProfile{
                display: none;
            }

          
            .firstsidebarLink{
                background-color: var(--color-pri-rgba);
                border: 1px solid var(--color-pri-rgba);
                display: flex;
                justify-content: space-between;
                padding: 6px 10px;
                border-radius: 5px;
                color: var(--color-pri);
                text-decoration: none;
            }
          

            .sidebarLink{
                background-color: transparent;
                border: 1px solid var(--color-pri-rgba);
                display: flex;
                justify-content: space-between;
                padding: 6px 10px;
                border-radius: 5px;
                color: var(--color-pri);
                text-decoration: none;

                &:hover{
                    background-color: var(--color-pri-rgba);
                }
            }
        }
    }
}

@media screen and (max-width: 750px){
    .dashboard-sidebar-navigation{
        .sidebar-ul{
           .sidebarUserProfile{
                padding: 20px 0;
                width: 100%;
                display: flex;
                gap: 10px;
                // justify-content: center;
                align-items: center;

                span{
                    font-size: 1.2rem;
                }

                .icon{
                    font-size: 1.5rem;
                    cursor: pointer;

                    &:hover{
                        color: var(--white-i);
                    }
                }

           }

        }
    }
}