.githubPortfolio-links {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px 20px;
    z-index: 10;

    .githubPortfolio-links-card{
        text-decoration: none;
        height: 280px;
        width: 330px;
        background-color: rgba(0, 0, 0, 0.322);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        transition: 0.3s ease, transform 0.2s ease;
        cursor: pointer;
        
        &:hover{
            background-color: rgba(255, 255, 255, 0.096);
            transform: scale(1.1);
        }

        .icon {
            position: relative;
            font-size: 8rem;
            color: var(--white-i);
        }

        .portfolio-links-h3 {
            margin: 0 auto;
            font-size: 1.2rem;
            color: var(--color-pri);
            display: flex;
            flex-direction: column;
            text-transform: capitalize;
            text-align: center;

            span {
                font-size: 1rem;
                font-weight: 400;
                margin-top: 3px;
                color: var(--white-i);
            }
        }
    }
}





  
@media (max-width: 1060px) {
    .githubPortfolio-links {
        justify-content: space-around;

        .portfolio-links-card {

            &:hover{
                transform: scale(1);
            }
        }
    }
}
