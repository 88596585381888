.contact-container{
    position: relative;
    list-style-type: none;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap-reverse;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
    background: var(--blue-transp);
    padding: 20px;
    border-radius: 50px;
    z-index: 2;

    .contact-left-box{
        background-color: var(--white);
        max-width: 550px;
        border-radius: 50px;

        .contact-image {
            width: 100%;
            display: block;
            border-radius: 25px;
        }
    }

    .contact-right-box{
        width: 550px;
        padding: 30px;

        .contact-form{
            padding: 10px;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 30px;

            .contact-form-input, .contact-form-textarea {
                width: 100%;
                padding: 10px;
                color: var(--white);
                background-color: var(--blue-transp);
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 2px solid var(--color-pri);
                border-radius: 5px 5px 0 0;
                outline: none;

                &::placeholder{
                    font-size: 1rem;
                    color: var(--gray);
                }
            }
            .contact-form-textarea{
                width: 100%;
                height: 145px;
                resize: none;
            }

            
            .contact-form-button {
                text-decoration: none;
                height: 50px;
                width: 200px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                background-color: var(--color-pri-rgba);
                color: var(--white);
                transition: 0.2s ease;
                overflow: hidden;
                position: relative;
                font-size: 1.5rem;
                font-weight: 300px;
                border: 2px solid var(--color-pri-rgba);
                border-radius: 5px;

                &:hover {
                    border: 2px solid var(--color-pri);
                    background-color: transparent;
                }
            }
            
            .contact-form-button::before {
                position: absolute;
                content: "";
                height: 100%;
                width: 65%;
                left: 0;
                background-color: rgba(255, 255, 255, 0.171);
                transition: 0.2s ease;
            }
            .contact-form-button::after {
                position: absolute;
                content: "";
                height: 100%;
                width: 50%;
                left: 0;
                background-color: rgba(255, 255, 255, 0.089);
                transition: 0.4s ease;
            }
            .contact-form-button:hover::before {
                position: absolute;
                content: "";
                height: 100%;
                width: 0%;
                left: 0;
            }
            .contact-form-button:hover::after {
                position: absolute;
                content: "";
                height: 100%;
                width: 0%;
                left: 0;
            }
        }
    }
}







/* .contact-right-box button{
} */

@media screen and (max-width: 1200px){
    .contact-container{
        justify-content: center;
    }
}

@media screen and (max-width: 820px){
    .contact-container{
        padding: 10px;
    }
    .contact-right-box{
        padding: 10px;
    }
    .contact-right-box form{
        gap: 20px;
    }
    .contact-right-box textarea{
        height: 100px;
    }
}

@media screen and (max-width: 450px){
    .contact-container{
        .contact-right-box{
            .contact-form{
                align-items: center;
            }

        } 
    }
   
}