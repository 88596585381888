
.coin-container {
    display: flex;
    justify-content: center;
}

.coin-row {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    /* height: 80px; */
    border-bottom: 1px solid #d7d7d7;
    max-width: 800px;
}

.coin {
    display: flex;
    align-items: center;
    padding-right: 5px;
    max-width: 300px;
}

.coin h1 {
   font-size: 16px;
   width: 150px;
}
.coin img {
    height: 30px;
    width: 30px;
    margin-right: 10px;
}

.coin-symbol {
    text-transform: uppercase;
}

.coin-data {
    display: flex;
    text-align: center;
    justify-content: space-between;
    width: 100%;
    font-size: 0.8rem;
}

.coin-price {
    width: 80px;
}

.coin-volume {
    width: 120px;
    
}

.coin-marketcap {
    width: 200px;
}

.coin-percent {
    width: 50px;
}

.red {
    color: #f00606;
}

.green {
   color: #11d811;
}

.coin-input {
    padding-left: 16px;
    width: 300px;
    height: 50px;
    border-radius: 4px;
    border-radius: none;
    background-image: linear-gradient(
        -225deg,
        #ac32e4 0%,
        #7918f2 48%,
        #4801ff 100%
    );
}
.coin-input::placeholder {
    color: #e2e2e2;
}

@media screen and (max-width: 900px) {
    .coin {
        flex-direction: column;
        padding: 0;
        max-width: 100%;
        padding-right: 5px;
        align-items: left;
    }
    
    .coin-volume {
        display: none;
    } 
    .coin-marketcap {
        display: none;
    }
    
}

/*copy*/
/* .coin-container {
    display: flex;
    justify-content: center;
}

.coin-row {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    height: 80px;
    border-bottom: 1px solid #d7d7d7;
    max-width: 900px;
}

.coin {
    display: flex;
    align-items: center;
    padding-right: 24px;
    max-width: 300px;
}

.coin h1 {
   font-size: 16px;
   width: 150px;
}
.coin img {
    height: 30px;
    width: 30px;
    margin-right: 10px;
}

.coin-symbol {
    text-transform: uppercase;
}

.coin-data {
    display: flex;
    text-align: center;
    justify-content: space-between;
    width: 100%;
}

.coin-price {
    width: 110px;
}

.coin-volume {
    width: 200px;
    
}

.coin-marketcap {
    width: 240px;
}

.coin-percent {
    width: 80px;
}

.red {
    color: #f00606;
}

.green {
   color: #11d811;
}

@media screen and (max-width: 900px) {
    .coin {
        flex-direction: column;
        padding: 0;
        max-width: 100%;
        padding-right: 5px;
        align-items: left;
    }
    
    .coin-volume {
        display: none;
    } 
    .coin-marketcap {
        display: none;
    }
    
} */


/* .coin-app {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
    color: #fff;
    }
    
    .coin-search {
    margin-bottom: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
    
    .coin-text {
    margin-bottom: 32px;
    text-align: center;
    
    }
    
    .coin-input {
    padding-left: 16px;
    width: 300px;
    height: 50px;
    border-radius: 4px;
    border-radius: none;
    background-image: linear-gradient(
        -225deg,
        #ac32e4 0%,
        #7918f2 48%,
        #4801ff 100%
    );
    }
    
    .coin-input::placeholder {
    color: #e2e2e2;
    } */