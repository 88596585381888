
.big{
    animation:bigText 1.2s infinite;
    color:green;
}
@keyframes bigText{
    0%{     color: #000;    }
    49%{    color: #000; }
    60%{    color: transparent; }
    99%{    color:transparent;  }
    100%{   color: #000;    }
}

.kitty-class{
    height:100px;
    width:100px;
    cursor: pointer;
}