.education-container{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;


    .education-card{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        width: 250px;
        background-color: rgba(0, 0, 0, 0.322);
        padding: 10px;
        border-radius: 50px;
        box-shadow: rgba(100, 99, 99, 0.24) 0px 3px 8px;

        .education-card-icons{
            padding: 5px;
            width: 100%;
            height: 240px;
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            align-items: center;
            border-radius: 40px 40px  0 0;
            background: rgb(5, 7, 37);
            background-size: cover;
            background-position: center;
            border: 2px solid var(--color-pri);

            .icon{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60px;
                height: 60px;
                background-color: var(--color-pri-rgba);
                border-radius: 5px;
                font-size: 3rem;
                color: var(--color-pri);
            }
        }

        .education-card-info{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            .title{
                font-size: 1.2rem;
                font-weight: 300;
                letter-spacing: 1px;
            }

            .education-date{
                background: rgb(5, 7, 37);
                position: relative;
                font-size: 1.1rem;
                border: 2px solid var(--color-pri);
                border-radius: 0 0 40px 40px;
                height: 50px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--color-pri);
                transition: 0.2s ease;
                overflow: hidden;
            }
        }
    }
}

@media screen and (max-width: 450px) {
    .education-card{
        width: 100%;
    }
}