.VideoPlayer-container{
     margin: 0;
    background-color: #282c34;
    text-align: center;
    justify-content: flex-start;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    .video-box{
        width: 100%;
    }
    
}



//////////////////
/// 
/// 
select {
  border: 0 none;
  color: white;
  background: transparent;
  background-color: #282c34;
  font-size: 20px;
  font-weight: bold;
  padding: 2px 10px;
}

.intro > p {
  margin: 0 10%;
  padding-bottom: 25px;
  text-align: left;
}

.video-inputs {
  display: inline-block;
  padding: 5px;
}

input {
  font-size: 16px;
}

input:not(:first-of-type) {
  margin-left: 0px;
}

video {
  margin: 30px;
  width: 500px;
  max-width: 85%;
}

.contacts {
  display: flex;
  flex-wrap: wrap;
}

.contact-card {
  flex-basis: 250px;
  margin: 20px;
}

.contact-card > img {
  width: 100%;
  height: auto;
}

.contact-card > h3 {
  text-align: center;
}

.contact-card > p {
  font-size: 12px;
  text-align: left;
}

button {
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.green {
  background-color: #4caf50;
  color: white;
}

.red {
  background-color: #f44336;
  color: white;
}

.blue {
  background-color: #008cba;
  color: white;
}

.black {
  background-color: #555555;
  color: white;
}

button:hover {
  cursor: pointer;
}

ul {
  margin: 0;
  padding: 10px;
}

img {
  width: 40%;
  cursor: pointer;
}