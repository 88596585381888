.slider {
    position: relative;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-image{
    width: 100%;
    /* width: 1000px; */
    /* height: 600px; */
    border-radius: 10px;
    /* z-index: 10;
    cursor: pointer */
    /* user-select: none; */
}
.right-arrow{
    position: absolute;
    top: 50%;
    right: 32px;
    font-size: 3rem;
    color: #000;
    /* z-index: 10;*/
    cursor: pointer 
}
.left-arrow{
    position: absolute;
    top: 50%;
    left: 32px;
    font-size: 3rem;
    color: #000;
    /* z-index: 10; */
    cursor: pointer
}

.slide{
    /* opacity: 0; */
    /* transition-duration: 1s ease; */
    /* transition: 1s ease; */
}
.slide .active{
  /* opacity: 1;
  transition: 1s ease; */
  /* transition-duration: 1s; */
  /* transform: scale(1.08); */
}