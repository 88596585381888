.EngLessonB-Irregular-verbs-container{
    width: 100%;
    list-style-type: none;

    li{
        max-width: 800px;
        margin: 0 auto;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid var(--gray);
       

        span{
            max-width: 330px;
            display: block;
            border: 1px solid var(--gray);
            background-color: var(--color-sec);
            padding: 5px 2px;
            text-align: center;
        }
        p{
            max-width: 330px;
            margin: 0;
            padding: 8px 10px 0;
            display: flex;
            span{
                background-color: var(--color-sec-rgba);
                border: none;
                margin-left: 10px;
                padding:0 5px;
            }
        }
    }
}