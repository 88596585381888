.sectionContainer{
    margin: 150px 0 200px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
    z-index: 5;

    .sectionTitle{
       margin: 0 auto;
       display: flex;
       flex-direction: column;
       align-items: center;
       padding-top: 30px;
       padding-bottom: 50px;
       z-index: 6;

       .sectionTitle-h2 {
            color: var(--white);
            font-size: 2rem;
            font-weight: 300;
            padding-bottom: 10px;
            border-bottom: 2px solid var(--color-pri);
        }
    }
}


@media screen and (max-width: 650px){
    .sectionContainer{
        margin: 100px 0;
    }
}