.bg-circle1 {
    position: fixed;
    top: 0px;
    right: 0px;
    height: 200px;
    width: 200px;
    background-color: var(--color-pri);
    filter: blur(140px);
    z-index: 2;
}
.bg-circle2 {
    position: fixed;
    bottom: 100px;
    right: 250px;
    height: 200px;
    width: 200px;
    background-color: var(--color-sec);
    filter: blur(150px);
    z-index: 2;
}
.publicMain{
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1200px;
    z-index: 3;
}