.AccordionFAQ-container {
    background-color: var(--color-pri-rgba);
    color: var(--dark-blue);
    max-width: 600px;
    padding: 10px 20px 20px;
    border-radius: 12px;
    margin: 0 auto;
 
  h1 {
    text-align: center;
  }
  
  .container  {
    width: 300px;

    h2{
        margin: 0 auto;
        text-align: center;
        padding: 10px 0 20px 0;
        color: white;
    }
    .questions{
      button {
        background: var(--white);
        color: var(--dark-blue);
        border: 2px solid var(--dark-blue);
        border-radius: 5px;
        font-size:1rem;
        width: 25px;
        height: 25px;
      }
      
      section div,
      section p {
        background-color: var(--white);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        margin-top: 0px;
      }
      
      section p {
        padding-bottom: 20px;
      }
      
      section > div.closed {
        margin-bottom: 20px;
      }

    }

   
  }
  

}