.accordion__section{
    display: flex;
    flex-direction: column;
    width: 100%;

    .accordionX{
        margin-top: 10px;
        width: 100%;
        background-color: var(--color-pri2);
        color: var(--white-i);
        cursor: pointer;
        padding: 1rem;
        display: flex;
        align-items: center;
        border: 1px solid var(--color-pri);
        border-radius: 3px;
        outline: none;
        transition: background-color 0.6s ease;

        &:hover {
            background-color: var(--blue-transp-x);
        }

        &.active {
            background-color: var(--color-pri);
            color: var(--white-i);
            border-radius: 3px 3px 0 0;
        }

        .accordion__title {
            font-weight: 600;
            font-size: 1rem;
            width: 100%;
        }

        .accordion__icon {
            margin-left:auto;
            transition: transform 0.6s ease;
            color: var(--white-i);
        }
        .rotate {
            transform: rotate(90deg);
            
        }

      
    }
    
   
    
    .accordion__content {
        background-color: var(--gray);
        color: var(--dark-blue2);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        transition: max-height 0.6s ease;
        border-radius: 0 0 3px 3px;
        /* max-height: 0; */

        .active{
            height: 100%;
        }

        iframe{
            margin-top: 20px;
            min-width: 300px;
            height: 150px;
        }

        ul{
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            align-items: center;
            gap: 20px;

            li{
                padding: 0;

                button{
                    padding: 5px 10px ;
                }
            }
        }

        .accordion__text{
            color: var(--black);
            font-weight: 400;
            font-size: 1rem;
            padding: 1rem;
        }

        .wordForm{
            padding: 10px;
            margin: 20px 0;
            background-color: red;
            display: flex;

            input{
                width: 200px;
            }
        }
    }
}

