.shoppingList-container{
    background-color: pink;
    color: var(--dark-blue);

    h1,
    .shopping-list {
        margin: 0 auto;
        text-align: center;
        max-width: 400px;
    }
    .shopping-list {
        margin-top: 50px;
        background-color: white;
        border: 2px dashed black;

        ul{
            align-items: center;
            justify-content: center;
            display: flex;
            width: 100%;
            padding: 0;
            flex-direction: column;

            li {
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 200px;

            }

            .delete {
                background: transparent;
                border: none;
                color: red;
                font-weight: bold;
                font-size: 1rem;
            }
        }
    }
}