.ImageCarousel-container{
     text-align: center;

     .slider {
        position: relative;
        width: 100%;

        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 10px;
        background-color: yellowgreen;

        .left-arrow,
        .right-arrow {
            position: absolute;
            z-index: 1;
            font-size: 20px;
            top: 46%;
            color: white;
            background: black;
            border-radius: 50%;
            padding: 6px 8px;
            cursor: pointer;
            user-select: none;
        }

        .left-arrow {
            left: 5%;
        }
          
        .right-arrow {
            right: 5%;
        }

        .slide {
            min-width: 50%;
            height: 60vh;
            font-size: 50px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}