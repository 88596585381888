.reactUseState-container{
    padding: 15px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    input{
       
        border: 1px solid var(--color-pri);
        border-radius: 3px;
        padding: 5px 10px;
        width: 300px;
        font-size: 1rem;
        color: var(--black);
        outline: none;
    }

    p{
        margin: 0;
        padding: 5px;
        background-color: var(--color-sec2);
        border-radius: 3px;
    }

    button{
        background-color: var(--color-sec);
        border: 1px solid var(--color-pri);
        border-radius: 3px;
        padding: 5px 10px;
        width: 300px;
        font-size: 1.2rem;
        cursor: pointer;
        &:hover{
            background-color: var(--color-sec2);
        }
    }

    .reactUseState-code-box{
        padding: 10px;
        background-color: var();
        width: 100%;
        height: 100%;
        border: 1px solid var(--color-pri);
        border-radius: 5px;

        span{
            display: block;
            width: 100%;
            font-size: 1rem;
            font-weight: 300;
            letter-spacing: 0.1rem;
        }
    }
}

@media screen and (max-width: 800px){
    .reactUseState-container{
        .reactUseState-code-box{
            width: 330px;
        } 
    }
}