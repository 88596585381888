.content-row-container{
    margin-top: 10px;
    margin-bottom: 30px;
    background-color: var(--dark-blue2);
    border-radius: 10px;
    padding: 15px;
    width: 100%;

    .content-row-h3{
        margin: 0 0 15px;
        font-size: 1.5rem;
        font-weight: 500;
        padding-bottom: 10px;
        border-bottom: 1px solid var(--gray);
    }
}