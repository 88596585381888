.dashboard{
    position: relative;
    width: 100%;
    z-index: 1;

    .bg-circle1 {
        position: fixed;
        top: 0px;
        right: 0px;
        height: 200px;
        width: 200px;
        background-color: var(--color-pri);
        filter: blur(140px);
        z-index: 2;
    }
    .bg-circle2 {
        position: fixed;
        bottom: 100px;
        right: 250px;
        height: 200px;
        width: 200px;
        background-color: var(--color-sec);
        filter: blur(150px);
        z-index: 2;
    }

    .content{
        padding-top: 70px;
        width: 100%;
        display: flex;
        z-index: 5;
        
        .mainContainer{
            margin: 0 0 0 225px;
            padding: 15px;
            width: 100%;

            z-index: 6;
        }
        .mainContainerFullwidth{
            margin: 0;
            padding: 15px;
            width: 100%;
            z-index: 6;
        }
    }

}

@media screen and (max-width: 750px){
    .dashboard{
        .content{
            .mainContainer{
                margin: 0;
            }
        }
    }
}


