.footer-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 50px;
    z-index: 5;

    .footer-btn-to-top{
        font-size: 1.3rem;
        margin: 50px auto 0;
        text-align: center;
        text-transform: capitalize;
        
        a {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            color: var(--color-pri);
            cursor: pointer;
            text-decoration: none;

            &:hover {
                color: var(--color-sec);
            }
        }
        
    }

    /*footer social-links*/
    .social-links {
        text-decoration: none;
        padding: 10px;
        margin: 20px auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
    .social-links span {
        height: 45px;
        width: 170px;
        font-size: 1.3rem;
        color: var(--color-pri);
        margin: 0 5px;
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        backdrop-filter: blur(10px);
    }

    .social-links .social {
        height: 45px;
        width: 45px;
        background-color: rgba(0, 0, 0, 0.548);
        color: var(--color-pri);
        margin: 0 5px;
        padding: 10px;
        backdrop-filter: blur(10px);
        cursor: pointer;
        /* transition: background 0.2s ease; */
        transition: 0.2s ease;
    }
    
    .social-links .social:hover {
    background-color: rgba(255, 255, 255, 0.336);
    fill: var(--color-pri);
    }
}



@media screen and (max-width: 820px){
    .social-links {
        padding: 10px 10px 100px;
    }
}