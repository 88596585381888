.adminGitportfolio-add{
    margin: 20px auto;
    max-width: 430px;
    background-color: var(--blue-transp);
    padding: 20px;
    border-radius: 10px;
   
    .adminGitportfolio-add-h2{
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }
    .adminGitportfolio-add-form{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .adminGitportfolio-add-input{
            width: 100%;
            padding: 3px 10px;
        }

        .adminGitportfolio-add-button{
            border: none;
            width: 100%;
            padding: 10px;
            background-color: var(--color-sec);
            color: var(--white-i);
            cursor: pointer;

            &:hover{
                background-color: var(--color-sec2);
            }
        }
    }
}

.adminGitportfolio-cards {
    margin-top: 30px;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px 20px;
    z-index: 10;

    .adminGitportfolio-single-card{
        text-decoration: none;
        height: 280px;
        width: 330px;
        background-color: rgba(0, 0, 0, 0.322);
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        transition: 0.3s ease, transform 0.2s ease;
        cursor: pointer;
        
        &:hover{
            background-color: rgba(255, 255, 255, 0.096);
            transform: scale(1.1);
        }

        .icon {
            position: relative;
            font-size: 8rem;
            color: var(--white-i);
        }

        .adminGitportfolio-single-card-h3{
            margin: 0 auto;
            font-size: 1.2rem;
            color: var(--color-pri);
            display: flex;
            flex-direction: column;
            text-transform: capitalize;
            text-align: center;

            span {
                font-size: 1rem;
                font-weight: 400;
                margin-top: 3px;
                color: var(--white-i);

                
            }
        }
    }

    .delete-btn{
        border: none;
        background-color: var(--red-transp);
        padding: 3px 5px;
        color: var(--white-i);
        cursor: pointer;
        &:hover{
            background-color: var(--red);
        }
    }
}





  
@media (max-width: 1060px) {
    .adminGitportfolio-cards {
        justify-content: space-around;

        .adminGitportfolio-single-card {

            &:hover{
                transform: scale(1);
            }
        }
    }
}
