.login-container{
    margin: 0 auto;
    max-width: 1200px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;

    .login-form{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 500px;
        max-height: 500px;
        z-index: 5;

        .login-card{
            position: absolute;
            width: 330px;
            background-color: var(--blue-transp);
            list-style-type: none;
            border-radius: 20px;
            padding: 40px 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;

            .login-input {
                width: 100%;
                min-width: 240px;
                padding: 10px;
                color: var(--white);
                background-color: var(--blue-transp);
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 2px solid var(--color-sec);
                outline: none;
                border-radius: 5px 5px 0 0;
            }
        }
    }
}




/*Login button*/
.login-button {
    padding: 10px;
    width:240px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--color-sec-rgba);
    color: var(--white);
    transition: 0.2s ease;
    overflow: hidden;
    position: relative;
    font-size: 1.3rem;
    font-family: var(--font-Inter);
    font-weight: 300px;
    border: 2px solid var(--color-sec);
    border-radius: 5px;
}
.login-button:hover {
    border: 2px solid var(--color-sec);
    background-color: transparent;
}
.login-button::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 65%;
    left: 0;
    background-color: rgba(255, 255, 255, 0.171);
    transition: 0.2s ease;
}
.login-button::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 50%;
    left: 0;
    background-color: rgba(255, 255, 255, 0.089);
    transition: 0.4s ease;
}
.login-button:hover::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 0%;
    left: 0;
}
.login-button:hover::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 0%;
    left: 0;
}

.login-link{
    color: var(--white-i);
    cursor: pointer;
    font-size: 1rem;
}

.login-link:hover{
    color: var(--color-sec);
}