.scrimba-container{
    pre{
        margin: 10px 0;
        padding: 5px;
        background-color: var(--color-pri);
        color: var(--black);
    }
    h4{
        margin: 10px 0;
        background-color: var(--color-sec2);
        font-size: 1rem;
        font-weight: 400;
    }
}