
.EngWords-click-btn{
    margin: 0 auto;
    background-color: var(--color-pri-rgba);
    color: var(--white);
    padding: 8px 15px;
    border-radius: 5px;

    &:hover{
        background-color: var(--color-sec2);
    }
}
