.dashboardContent-Container{
    width: 100%;

    .dashboardContent-path{
        margin: 0;
        width: 100%;
        padding: 8px 0;
        font-size: 1rem;
        font-weight: 300;
    }

    .dashboardContent{
        width: 100%;
        padding: 10px 0;
    }
}