.grocery-list-container{
    background-color: var(--white);
    padding: 15px;
    border-radius: 5px;
}

.fontRoboto{
    font-family: 'Roboto', sans-serif;
}

.fontLicorice{
    font-family: 'Licorice', cursive;
}
.fontMontserrat{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 50px;
}
select,option , option:hover{
    border:0px;
    outline:0px;
    border-radius: 0;
} 
