@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

:root {
    --color-pri: rgb(82, 188, 250);
    --color-pri2: rgba(82, 188, 250, 0.486);
    --color-pri3: rgba(48, 175, 249, 0.486);
    --color-pri-rgba: rgba(82, 188, 250, 0.245);
    --color-pri-rgba2: rgba(28, 64, 84, 0.245);
    --color-sec: rgba(175, 233, 17, 0.269);
    --color-sec2: rgba(206, 235, 124, 0.37);
    --color-sec-rgba: rgba(175, 233, 17, 0.274);
    --black:#000000;
    --dark-blue: rgb(16, 16, 75);
    --dark-blue2: rgb(12, 12, 48);
    --blue-transp: rgba(0, 0, 0, 0.116);
    --blue-transp-x: rgba(0, 0, 0, 0.614);
    --white: #ffffff;
    --white-i: rgb(209, 217, 252);
    --gray: gray;
    --red: #ff0000;
    --red-transp: #ff000061;
    
    --font-Nunito:'Nunito', sans-serif;
}

body{
    margin: 0;
    font-family: var(--font-Nunito);
    font-size: 16px;
    background-color: var(--dark-blue);
    color: var(--white);
    min-height: 100vh;
    animation: move 1s linear infinite forwards;
}

body::before {
    content: "";
    position: fixed;
    height: 200px;
    width: 200px;
    background-color:var(--color-pri);
    filter: blur(140px);
}
body::after {
    content: "";
    position: fixed;
    top: 200px;
    left: 250px;
    height: 200px;
    width: 200px;
    background-color:  var(--color-sec);
    filter: blur(140px);
}

h1{
    margin: 0;
}

