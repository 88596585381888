.addWord-form-container{
    margin: 0 auto 20px;
    padding: 20px;
    background-color: var(--dark-blue2);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 500px;
    border-radius: 5px;

    .addWord-input{
        width: 100%;
        border-radius: 3px;
        border: none;
        padding: 5px;
        color: var(--black);
        font-size: 1.1rem;
        outline: none;
    }

    .addWord-button{
        background-color: var(--color-sec2);
        color: var(--white-i);
        width: 100%;
        border-radius: 3px;
        border: none;
        padding:  8px 5px;
        font-size: 1.1rem;
        cursor: pointer;

        &:hover{
            background-color: var(--color-sec-rgba);
        }
    }
}