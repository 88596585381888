
.js-navigation-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    
    .js-navigation-cards-box{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 20px;

        .js-navigation-card{
            width: 300px;
            height: 110px;
            background-color: var(--color-pri-rgba);
            color: var(--white-i);
            border: 1px solid var(--color-pri);
            border-radius: 3px;
            padding: 6px ;
            margin-bottom: 20px;
            font-size: 1.2rem;
            box-shadow: rgba(100, 100, 111, 0.373) 0px 7px 29px 0px;
            cursor: pointer;
            &:hover{
                background-color: var(--blue-transp);
            }
    
            .js-navigation-card-h3{
                margin: 0;
                width: 100%;
                text-align: center;
                font-size: 1.1rem;
                font-weight: 400;
                letter-spacing: 0.1rem;
                border-bottom: 1px solid var(--white-i) ;
            }
    
            .js-navigation-card-span{
                display: block;
                width: 100%;
                height: 74px;
                border-radius: 0 0 3px 3px;
                padding: 6px;
                font-size: 1.1rem;
                background-color: var(--dark-blue2);
            }
        }
    }

    .js-navigation-p{
        margin: 50px 0 0;
        width: 100%;
        text-align: center;
    }
}
.js-coursesContainer{
    padding: 20px;
    ul{
        list-style-type: none;
    }
    span{
        padding: 5px 0;
        display: block;
    }
    .courses-code-container{
        margin: 20px 0;
        padding: 10px;
        background-color: var(--color-pri2);
        color: var(--black);
    }
}






