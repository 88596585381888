
.scroll-down {
    transform: rotate(90deg);
    position: absolute;
    top: 360px;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    color: var(--color-pri);
}

.scroll-down:hover {
    color: var(--color-sec);
}
.scroll-down hr {
    width: 20px;
}
.scroll-down h5 {
    font-size: 1.1rem;
    text-transform: capitalize;
    font-weight: 300;
}
.scroll-down .scroll {
    transform: rotate(-90deg);
    font-size: 1.3rem;
}

@media screen and (max-width: 820px){
    .scroll-down {
       display: none;
    }
}