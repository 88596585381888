.array1-navigation{
    padding: 20px 10px;
    background-color: green;
    display: flex;
    justify-content: space-between;
}
.links{
    display: block;
    background-color: red;
    margin: 5px 10px;
}