.GitHubUserSearch-container {
    margin: 0;
    padding: 10px;

  .main {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    .search-form {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      gap: 20px;

      input,
      button {
        width: 280px;
        padding: 10px;
        border-radius: 4px;
      }

      input {
        border: 1px solid grey;
        outline: none;
      }

      button {
        margin-left: 10px;
        background-color: chartreuse;
        border: 1px solid chartreuse;
      }
    }

    .user {
      height: 100px;
      margin: 10px;
      padding: 10px;
      border: 1px solid black;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      a{
        color: var(--white);
        text-decoration: none;
      }
    }
  }
}
  

  

  
  