.studentsHomeContainer {
    display: flex;
    flex-direction: column;

   .students-header{
    padding: 20px;
}
}

// .students-list-container{
//     padding: 10px;
//     border-radius: 5px;
//     background-color: var(--white);
// }
// .students-info-card{
//     margin: 5px;
//     background-color: var(--blue);
//     color: var(--white);
//     padding: 10px;
//     border-radius: 5px;
//     display: flex;
//     justify-content: space-between;
//     flex-wrap: wrap;
// }
// .students-name{
//     font-size: 1.25rem;
//     font-weight: 400;
// }
// .students-email{
//     font-size: 1rem;
//     font-weight: 500;
// }
// .student-details{
//     padding: 10px;
//     background-color: var(--blue);
//     color:var(--red);
//     border-radius: 5px;
// }
// .student-h3{
//     padding: 30px 0;
// }
