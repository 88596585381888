.edukus-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .edukus-button{
        margin-bottom: 20px;
        background-color: var(--color-sec);
        color: var(--white-i);
        padding: 6px 15px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    
        &:hover{
            background-color: var(--color-sec2);
        }
    
    }
    .edukus-container-accordion{
        padding: 5px;
        // background-color: var(--color-sec-rgba);
        width: 100%;
        display: flex;
        justify-content: center;
        
    }

    h3{
        margin-top: 20px;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 320px;

        p{
            margin: 10px auto 0;
            padding: 0;
            color: var(--red);
            font-size: 1rem;
            font-weight: 600;
        }

        input{
            width: 100%;
            padding: 6px 10px;
            border-radius: 3px;
            border: none;
            font-size: 1.1rem;
            font-weight: 500;
        }

    }

}

