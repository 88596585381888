.homeHeader{
    margin: 0 auto;
    padding: 20px 15px;
    max-width: 1200px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    transform: translateY(0);
    opacity: 1;
  
    animation: show-header 1s ease 1 forwards;

    .header-link-box{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 20px;
        z-index: 20;

        .header-contact-link {
            text-decoration: none;
            height: 50px;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-color: var(--color-pri-rgba);
            color: var(--white);
            transition: 0.2s ease;
            overflow: hidden;
            position: relative;
            font-size: 1.5rem;
            font-weight: 300px;
            border: 2px solid var(--color-pri-rgba);
            border-radius: 5px;
        }
        .header-contact-link:hover {
            border: 2px solid var(--color-pri);
            background-color: transparent;
        }
        .header-contact-link::before {
            position: absolute;
            content: "";
            height: 100%;
            width: 65%;
            left: 0;
            background-color: rgba(255, 255, 255, 0.171);
            transition: 0.2s ease;
        }
        .header-contact-link::after {
            position: absolute;
            content: "";
            height: 100%;
            width: 50%;
            left: 0;
            background-color: rgba(255, 255, 255, 0.089);
            transition: 0.4s ease;
        }
        .header-contact-link:hover::before {
            position: absolute;
            content: "";
            height: 100%;
            width: 0%;
            left: 0;
        }
        .header-contact-link:hover::after {
            position: absolute;
            content: "";
            height: 100%;
            width: 0%;
            left: 0;
        }

        /*Login button*/
        .header-contact-login-link {
            text-decoration: none;
            height: 50px;
            width: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            background-color: var(--color-sec-rgba);
            color: var(--white);
            transition: 0.2s ease;
            overflow: hidden;
            position: relative;
            font-size: 1.5rem;
            font-weight: 300px;
            border: 2px solid var(--color-sec);
            border-radius: 5px;
        }
        .header-contact-login-link:hover {
            border: 2px solid var(--color-sec);
            background-color: transparent;
        }
        .header-contact-login-link::before {
            position: absolute;
            content: "";
            height: 100%;
            width: 65%;
            left: 0;
            background-color: rgba(255, 255, 255, 0.171);
            transition: 0.2s ease;
        }
        .header-contact-login-link::after {
            position: absolute;
            content: "";
            height: 100%;
            width: 50%;
            left: 0;
            background-color: rgba(255, 255, 255, 0.089);
            transition: 0.4s ease;
        }
        .header-contact-login-link:hover::before {
            position: absolute;
            content: "";
            height: 100%;
            width: 0%;
            left: 0;
        }
        .header-contact-login-link:hover::after {
            position: absolute;
            content: "";
            height: 100%;
            width: 0%;
            left: 0;
        }
    }
}

@keyframes show-header {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }
}

@media screen and (max-width: 820px){
    .homeHeader{
        .header-link-box{
            display: none;
        }
    }
}