.navigation {
    position: fixed;
    left: 40px;
    top: 50%;
    transform: translateY(-50%) translateX(-100px);
    background-color: rgba(0, 0, 0, 0.596);
    padding: 13px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 23px;
    border-radius: 50px;
    opacity: 0;
    z-index: 100;
    animation: fade-nav-in 1s ease 1 1s forwards;

    .navLink {
        background-color: var(--white-i);
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        text-decoration: none;

        .icon {
            display: block;
            background-color: var( --dark-blue2);
            width: 41px;
            height: 41px;
            font-size: 1.5rem;
            border-radius: 50%;
            padding: 9px;
            color: var(--white-i);
            display: flex;
            justify-content: center;
            align-items: center;
            transition: fill 0.1s ease;

            &:hover{
               color: var(--color-sec);
            } 
        }

        &:hover{
            background-color: var(--color-sec);
        }
    }

    .active-navLink {
        background-color: var(--color-pri);
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;

        .icon {
            display: block;
            background-color: var( --dark-blue2);
            width: 41px;
            height: 41px;
            font-size: 1.5rem;
            border-radius: 50%;
            padding: 9px;
            color: var(--color-pri);
            display: flex;
            justify-content: center;
            align-items: center;
        }
     }
    
    
}

@keyframes fade-nav-in {
    to {
      transform: translateY(-50%) translateX(0);
      opacity: 1;
    }
}



  
@media screen and (max-width: 820px) {
    .navigation {
        backdrop-filter: blur(30px);
        left: 50%;
        transform: translateX(-50%) translateY(100px);
        opacity: 0;
        top: 84%;
        background-color: rgba(0, 0, 0, 0.596);
        padding: 10px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 23px;
        border-radius: 100px;
        animation: fade-nav-bottom 1s ease forwards;
    }
    @keyframes fade-nav-bottom {
      to {
        transform: translateX(-50%) translateY(0);
        opacity: 1;
      }
    }  
}
  
@media  screen and (max-width: 500px) {
    .navigation {
        top: 86%;
        padding: 12px 20px;
        max-width: 95%;
        border-radius: 50px;
    }
}
    