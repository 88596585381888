.searchWord-form-container{
    margin: 0 auto 20px;
    padding: 20px;
    background-color: var(--color-sec2);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    max-width: 500px;
    border-radius: 5px;

    .searchWord-input{
        width: 100%;
        border-radius: 3px;
        border: none;
        padding: 5px;
        color: var(--black);
        font-size: 1.1rem;
        outline: none;
    }
}

.words-style-box{
    margin: 0 auto 10px;
    max-width: 800px;
    padding: 6px;
    background-color: var(--color-sec2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    cursor: pointer;
    border-radius: 5px;

    &:hover{
        background-color: var(--color-pri-rgba);
    }

    .words-style-div{
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 10px;

        .words-style-span{
            padding-top: 3px;
            display:flex;
            align-items: center;
        }

        .words-style-h3 {
            margin: 0;
            color: var(--white);
            font-size: 1.5rem;
            font-weight: 400;
        }
    }

    .word-LT{
        padding: 0 10px;
        color: var(--black);
        background-color: var(--white);
        font-size: 1.4rem;
        font-weight: 400;
        display: flex;
        align-items: center;

        .word-LT-sound{
            padding-left: 20px;
            color: var(--gray);
            display: flex;
            align-items: center;
        }

        .word-delete-button{
            margin: 0;
            padding: 0 0 0 20px;
            background-color: var(--white);
            border: none;
            cursor: pointer;
            color: var(--red-transp);
            font-size: 1.5rem;
            display: flex;
            align-items: center;
        }
    
    }
    
}



/*pagination*/
.wordsPagination-container{
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0;

    .paginationBttns{
        width: 80%;
        height: 40px;
        list-style:none;
        display: flex;
        justify-content: center;
    }
    
    .paginationBttns a{
        padding: 5px 10px;
        margin: 8px;
        border-radius: 5px;
        border: 1px solid var(--white);
        color: var(--white-i);
        cursor: pointer;
    }
    .paginationBttns a:hover{
        color: var(--white);
        background-color: var(--color-sec2);
    }
    .paginationActive a {
        color: var(--white);
        background-color: var(--color-sec2);
    
    }
    
}

.word-container{
    margin: 15px 0;
    padding: 10px;
    background-color: var(--color-sec2);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    font-size: 1.3rem;
    font-weight: 400;

    p{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        .icon-color{
            color: var(--white-i);
        }
        .active{
            color: var(--dark-blue);
        }
    }

    .bottom{
        background-color: var(--white);
        color: var(--black);
        width: 100%;
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        span{
            margin: 0;
            display: block;
        }
        .link{
            display: block;
            text-decoration: none;
            color: var(--dark-blue);
            border: 1px solid var(--dark-blue);
            padding:   3px 5px 0;
            border-radius: 5px;
            box-shadow: rgba(0, 0, 0, 0.41) 0px 5px 15px;
            cursor: pointer;

            &:hover{
                box-shadow: rgba(0, 0, 0, 0.846) 0px 5px 15px;
            }

            

        }
        .darkBlue{
            color: var(--dark-blue);
        }

        .red{
            color: var(--red);
        }
        .sec{
           color: green;
        }


        

    }

}






