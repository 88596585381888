.CurrencyConverter-row{
    margin:0;
    padding: 20px;
    background-color: var(--color-pri);
    width: 100%;
}

.CurrencyConverter-container{
    margin:0 auto;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.CurrencyConverter-App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(5px + 2nmin);
}
.CurrencyConverter-box{
    margin: 20px;
    text-align: center;
    background-color: #fff;
    border-radius: 10px;
    min-width: 300px;
    padding: 10px;

}
.CurrencyConverter-card {
    width: 100%;
    cursor: pointer;
    
}

.CurrencyConverter-card-body{
    color: black;
}

.CurrencyConverter-card.selected{
    border:3px solid green;
}

.CurrencyConverter-form {
    width: 100%;
    margin: 50px auto;
}

.CurrencyConverter-form.fa-exchange-alt {
    line-height: unset;
    padding: 0 10px;
}

.CurrencyConverter-list-container{
    color: black;
    
}
.CurrencyConverter-list-group{
    list-style-type: none;
}