.list-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 70vh;
    background: #fff;
}
.Container{
    position: absolute;
    width: 100%;
    top: 40px;
}
.WrapContainer{
    padding: 10px;
    background: #fff;
}
.WrapDiv{
    background: #fff;
    padding: 0;
    color: rgba(14,42,53,1);
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    text-align: center;
    cursor: pointer;
    box-shadow: 0 0 20px #e5e5e5;
}
.DropdownLine{
    margin: 0;
    padding-left: 20px;
    text-align: left;
    
}
.DropdownLine a, .DropdownLine a:hover, .DropdownLine a:active{
    text-decoration: none;
    color: rgba(0,169,127,1);
}
.Dropdown {
    padding: 20px 30px 0;
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    color: #0e2a35;
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.Dropdown > div{
    display: flex;
    align-items: center;
    margin: 10px;
}
.Dropdown > span{
    width: 19px;
    min-width: 19px;
    height: 19px;
    border: 1px solid rgba(0,169,127,1);
    border-radius: 50%;
    position: relative;
}
.Dropdown > span img {
    min-width: 19px;
    position: absolute;
    left: 2px;
    bottom: 2px;
}
@media screen and (max-width: 500px){
    .Dropdown{font-size: 16px;}
}

.Wrap{
    background: #fff;
    padding: 16px 30px;
    color: rgba(14,42,53,1);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    cursor: pointer;
    position: absolute;
    height: 98px;
    top: 0;
    left: 0;
}
.Wrap > h1 {
    margin: 0;
    color: #0e2a35;
    font-family: 'Manrope', sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
}
.Wrap > span {
    margin: 0 36px 0 0;
    min-width: 50px;
    width: 50px;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Manrope', sans-serif;
    font-size: 36px;
    color: rgba(255,255,255,1);
    background: linear-gradient(180deg, #00a9a7 0%, #00ba89 10% );
}
