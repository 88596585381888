.dashboard-messages-container{
    background-color: var(--blue-transp);
    margin: 20px auto 20px;
    padding: 20px;
    border-radius: 10px;

    .dashboard-message-h2{
        font-size: 1.5rem;
        font-weight: 500;
        padding-bottom: 5px;
        border-bottom: 2px solid var(--color-pri);
    }

    .message-box{
        background-color: var(--color-pri-rgba);
        margin-top: 10px;
        padding: 10px;

        .messageContent{
            font-size: 1.2rem;
            font-weight: 400;
        }

    }
}